import { Dispatch, FC, useEffect, useState } from "react"

import { Checkbox, Typography } from "@mui/material"
import { ISuggestedCreateJobs } from "api/reactQuery/mutations/jobs.types"
import HelpLink from "components/common/link/helpLink"
import BasicTable from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"
import { PaycheckInterval } from "ts/enums/Class"
import { IClass } from "ts/interfaces/Class"
import { showDecimal } from "utils/decimalNumber"

import {
  ISuggestedJobsTypes,
  IUpdateToggleItem,
} from "./AddSuggestedJobs.types"
import {
  elementSuggestedJobs,
  msSuggestedJobs,
  psSuggestedJobs,
} from "./suggestedJobs"
import Table from "./Table"

interface IPropsAddSuggestedJobs {
  handleSuggestedJobs: (el: ISuggestedCreateJobs) => void
  setChecked: Dispatch<React.SetStateAction<ISuggestedCreateJobs[]>>
  checked: ISuggestedCreateJobs[]
  errorSuggested: string
  paymentClass: IClass | undefined
  handleOpenDialogCloseSuggestedJobs: () => void
}

const AddSuggestedJobs: FC<IPropsAddSuggestedJobs> = ({
  handleSuggestedJobs,
  setChecked,
  checked,
  errorSuggested,
  paymentClass,
  handleOpenDialogCloseSuggestedJobs,
}) => {
  const { t } = useTranslation()

  const [isCheckAll, setIsCheckAll] = useState(false)
  const [suggestedJobs, setSuggestedJobs] = useState<
    ISuggestedCreateJobs[] | []
  >([])
  const [isActiveInput, setIsActiveInput] = useState(false)

  const elementSuggestedJobsCopy: ISuggestedCreateJobs[] = JSON.parse(
    JSON.stringify(elementSuggestedJobs)
  )
  const msSuggestedJobsCopy: ISuggestedCreateJobs[] = JSON.parse(
    JSON.stringify(msSuggestedJobs)
  )
  const psSuggestedJobsCopy: ISuggestedCreateJobs[] = JSON.parse(
    JSON.stringify(psSuggestedJobs)
  )

  useEffect(() => {
    if (Number(paymentClass?.grade) <= 2 || paymentClass?.grade === "K") {
      elementSuggestedJobsCopy.forEach((elementJob) => {
        if (paymentClass?.paycheck_interval === PaycheckInterval.WEEKLY) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[0])
        } else if (
          paymentClass?.paycheck_interval === PaycheckInterval.BIWEEKLY
        ) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[1])
        } else {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[2])
        }
      })
      setSuggestedJobs(elementSuggestedJobsCopy)
    }

    if (Number(paymentClass?.grade) >= 3 && Number(paymentClass?.grade) <= 5) {
      msSuggestedJobsCopy.forEach((elementJob) => {
        if (paymentClass?.paycheck_interval === PaycheckInterval.WEEKLY) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[0])
        } else if (
          paymentClass?.paycheck_interval === PaycheckInterval.BIWEEKLY
        ) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[1])
        } else {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[2])
        }
      })
      setSuggestedJobs(msSuggestedJobsCopy)
    }

    if (Number(paymentClass?.grade) >= 6) {
      psSuggestedJobsCopy.forEach((elementJob) => {
        if (paymentClass?.paycheck_interval === PaycheckInterval.WEEKLY) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[0])
        } else if (
          paymentClass?.paycheck_interval === PaycheckInterval.BIWEEKLY
        ) {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[1])
        } else {
          typeof elementJob.salary === "object" &&
            (elementJob.salary = elementJob.salary[2])
        }
      })
      setSuggestedJobs(psSuggestedJobsCopy)
    }
  }, [paymentClass])

  const toggleAll = () => {
    setIsCheckAll(!isCheckAll)
    setChecked(suggestedJobs.map((el) => el))
    suggestedJobs.forEach((el) => (el.check = true))
    if (isCheckAll) {
      suggestedJobs.forEach((el) => (el.check = false))
      setChecked([])
    }
  }

  const updateToggleItem = ({ id, text, type }: IUpdateToggleItem) => {
    setChecked(
      checked.map((element) => {
        if (element.id !== id) {
          return element
        }
        switch (type) {
          case ISuggestedJobsTypes.TITLE:
            return { ...element, title: text }
          case ISuggestedJobsTypes.QUALIFICATIONS:
            return { ...element, qualifications: text }
          case ISuggestedJobsTypes.RESPONSIBILITIES:
            return { ...element, responsibilities: text }
          case ISuggestedJobsTypes.SALARY:
            return {
              ...element,
              salary: isNaN(Number(showDecimal(text)))
                ? 0
                : Number(showDecimal(text)),
            }
          default:
            return element
        }
      })
    )
  }

  return (
    <>
      <BasicTable
        head={
          <BasicTable.Row>
            <BasicTable.Cell minWidth="60px">
              <Checkbox
                onClick={toggleAll}
                checked={
                  checked.length === suggestedJobs.length &&
                  checked.length !== 0
                }
                indeterminate={
                  checked.length !== suggestedJobs.length &&
                  checked.length !== 0
                }
              />
            </BasicTable.Cell>
            <BasicTable.Cell minWidth="160px">
              <BasicTable.HeadText>{t("jobs.jobTitle")}</BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell minWidth="160px" tableCellProps={{ width: "50%" }}>
              <BasicTable.HeadText>
                {t("jobs.responsibilities")}
              </BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell minWidth="160px" tableCellProps={{ width: "50%" }}>
              <BasicTable.HeadText>
                {t("jobs.qualifications")}
              </BasicTable.HeadText>
            </BasicTable.Cell>
            <BasicTable.Cell minWidth="80px">
              <BasicTable.HeadText>{t("jobs.salary")}</BasicTable.HeadText>
            </BasicTable.Cell>
          </BasicTable.Row>
        }
        body={suggestedJobs.map((el, index) => (
          <Table
            el={el}
            key={index}
            updateToggleItem={updateToggleItem}
            suggestedJobs={suggestedJobs}
            handleSuggestedJobs={handleSuggestedJobs}
            setSuggestedJobs={setSuggestedJobs}
            setIsActiveInput={setIsActiveInput}
            isActiveInput={isActiveInput}
            handleOpenDialogCloseSuggestedJobs={
              handleOpenDialogCloseSuggestedJobs
            }
          />
        ))}
        bottomSlot={
          <Typography color="mockup.error50">{errorSuggested}</Typography>
        }
      />
      {process.env.REACT_APP_TYPE === "classequity" && <HelpLink />}
    </>
  )
}

export default AddSuggestedJobs
