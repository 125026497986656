import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { ITransactionItemClass } from "ts/interfaces/Transaction"

import { IGetClassesStoreTransactionsArgs } from "./classesStoreTransactions.types"

export const useQueryClassesStoreTransactions = ({
  classId,
  options,
}: IGetClassesStoreTransactionsArgs) =>
  useQuery<AxiosResponse<ITransactionItemClass[]>, AxiosError>(
    ["classesStoreTransactions", classId],
    async () =>
      axiosInstance(
        Endpoint.CLASSES_STORE_TRANSACTIONS.replace("ID", `${classId}`)
      ),
    options
  )
