import { FC } from "react"

import { AccordionSummary, Stack, Typography } from "@mui/material"
import { useQueryClassesById } from "api/reactQuery/queries/classes"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useTranslation } from "react-i18next"

import AutopayBox from "./autopayBox"

interface IProps {
  title: string
  selectedItemsAmount: number
  autopay?: boolean
  classId?: number
}

const Summary: FC<IProps> = ({
  title,
  selectedItemsAmount,
  autopay = false,
  classId,
}) => {
  const { t } = useTranslation()
  const isTablet = useIsBreakpointUp("tablet")

  const { data: classById } = useQueryClassesById({
    id: Number(classId),
    options: {
      enabled: !!classId,
    },
  })

  return (
    <AccordionSummary>
      <Stack width="100%">
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={{ mobile: autopay ? "4px" : "0px", tablet: "0px" }}
        >
          <Stack direction="row" gap="10px">
            {title}
            {autopay && isTablet && (
              <AutopayBox classById={classById?.data.paycheck_automatic} />
            )}
          </Stack>
          <Typography
            color="mockup.neutral50A"
            variant="body2"
            fontWeight={500}
          >
            {selectedItemsAmount} {t("transaction.selected").toLowerCase()}
          </Typography>
        </Stack>
        {autopay && !isTablet && (
          <AutopayBox classById={classById?.data.paycheck_automatic} />
        )}
      </Stack>
    </AccordionSummary>
  )
}

export default Summary
