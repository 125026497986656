import React from "react"

import { Checkbox } from "@mui/material"
import { Cell, Row } from "components/common/table/basicTable"
import { useTranslation } from "react-i18next"
import { TypographyHead } from "styles/common/typography"

interface HeadAllTransactionsProps {
  onChange: () => void
  isChecked: boolean
  isIndeterminate: boolean
}

const HeadAllTransactions = ({
  onChange,
  isChecked,
  isIndeterminate,
}: HeadAllTransactionsProps) => {
  const { t } = useTranslation()
  return (
    <>
      <Row>
        <Cell>
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            indeterminate={isIndeterminate}
          />
        </Cell>
        <Cell>
          <TypographyHead>{t("allTransactions.type")}</TypographyHead>
        </Cell>
        <Cell>
          <TypographyHead>
            {t("allTransactions.classAndTeacher")}
          </TypographyHead>
        </Cell>
        <Cell>
          <TypographyHead>{t("allTransactions.student")}</TypographyHead>
        </Cell>{" "}
        <Cell>
          <TypographyHead>{t("allTransactions.title")}</TypographyHead>
        </Cell>{" "}
        <Cell>
          <TypographyHead>{t("allTransactions.date")}</TypographyHead>
        </Cell>{" "}
        <Cell>
          <TypographyHead>{t("allTransactions.amount")}</TypographyHead>
        </Cell>
        <Cell></Cell>
      </Row>
    </>
  )
}

export default HeadAllTransactions
