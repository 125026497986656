import { ReactComponent as Logo } from "@brand_assets/svg/logo.svg"
import { Box, Grid } from "@mui/material"
import SignByEmail from "components/module/signAsStudent/signByEmail"
import AddSchoolForm from "components/module/signAsTeacher/addSchool/AddSchoolForm"
import FindSchoolForm from "components/module/signAsTeacher/findSchool"
import PersonalForm from "components/module/signAsTeacher/personalForm"
import { useAppSelector } from "hooks/store"
import { selectDataAuth, selectRole } from "store/auth/auth.selectors"

import { useDirectLink } from "../../../hooks/directLink"
import Finalization from "../../module/signAsStudent/finalization"
import BackgroundImageLeft from "../backgroundImageLeft"
import CheckRole from "../checkRole/CheckRole"

interface IProps {
  desktopView: boolean
  activePage: number
  registerType: "signUp" | "login"
  userSelected: () => JSX.Element | undefined
}

const RegisterLayout = ({
  userSelected,
  desktopView,
  activePage,
  registerType,
}: IProps) => {
  const roleType = useAppSelector(selectRole)
  const dataAuth = useAppSelector(selectDataAuth)

  useDirectLink()

  return (
    <Grid container minHeight="100vh" width="100%">
      {desktopView && <BackgroundImageLeft />}
      <Grid
        item
        width={{ desktop: "", mobile: "100%" }}
        px={{ desktop: 0, mobile: "10px" }}
        alignItems="center"
        justifyContent={{ desktop: "center", mobile: "stretch" }}
        flexDirection={{ desktop: "row", mobile: "column" }}
        display="flex"
        textAlign="center"
        flex="1 0 auto"
      >
        <Box maxWidth="100%" width="426px">
          {!desktopView && (
            <Box textAlign="left" my={5} width="115px">
              <Logo />
            </Box>
          )}
          {registerType === "signUp" && activePage === 0 && <CheckRole />}
          {registerType === "signUp" &&
            (roleType.teacher || dataAuth?.role === "teacher") &&
            ((activePage === 1 && userSelected()) ||
              (activePage === 2 && <PersonalForm />) ||
              (activePage === 3 && <FindSchoolForm />) ||
              (activePage === 4 && <AddSchoolForm />))}
          {registerType === "signUp" &&
            (roleType.student || dataAuth?.role === "student") &&
            ((activePage === 1 && userSelected()) ||
              (activePage === 2 && <SignByEmail />) ||
              (activePage === 3 && <Finalization />))}
          {registerType === "login" &&
            ((activePage === 0 && <CheckRole />) ||
              (activePage === 1 && userSelected()))}
        </Box>
      </Grid>
    </Grid>
  )
}

export default RegisterLayout
