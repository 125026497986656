import React from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Button, Stack, Typography, useTheme } from "@mui/material"
import { useMutationGoogleLoginStudent } from "api/reactQuery/mutations/googleLogin"
import { useMutationStudentLogin } from "api/reactQuery/mutations/students"
import GoogleButton from "components/common/googleButton"
import PasswordTextField from "components/common/textField/registerInput/passwordTextField"
import TextField from "components/form/common/field/textField"
import { useGoogle } from "hooks/google"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { Link, useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import {
  setActivePageLogin,
  setActivePageSignUp,
  setAuthData,
} from "store/auth/auth.slice"
import { UserRole } from "ts/enums/User"
import { getErrorMessage, getErrorMessageFromTab } from "utils/api"

import { defaultValues, validateSchema } from "./LoginAsStudent.config"

interface IUserAuth {
  username: string
  password: string
}

const LoginAsStudent = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const theme = useTheme()
  const dispatch = useAppDispatch()
  const methods = useForm({
    resolver: yupResolver(validateSchema),
    defaultValues: defaultValues,
  })

  const { mutate: googleLogin } = useMutationGoogleLoginStudent({
    option: {
      onSuccess: (data) => {
        dispatch(
          setAuthData({
            token: data.data.access_token,
            finalized: !data.data.finalization_required,
            role: UserRole.STUDENT,
          })
        )

        if (!data.data.finalization_required) {
          showSnackbar({
            title: t("noAuthFlow.loginSuccessful"),
          })
        } else {
          showSnackbar({
            title: t("noAuthFlow.yourAccountHasBeenCreated"),
          })
        }

        if (data.data.finalization_required) {
          navigate(RoutePath.SIGNUP, { state: "login" })
          dispatch(setActivePageSignUp(3))
        }
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessageFromTab(error),
          variant: "error",
        })
      },
    },
  })

  const { mutate: loginStudent } = useMutationStudentLogin({
    options: {
      onSuccess: (data) => {
        queryClient.invalidateQueries("userMe")
        showSnackbar({
          title: t("noAuthFlow.loginSuccessful"),
        })
        dispatch(
          setAuthData({
            token: data.data.access_token,
            finalized: true,
            role: UserRole.STUDENT,
          })
        )
        navigate(RoutePath.HOMEPAGE)
      },
      onError: (error) => {
        showSnackbar({
          title: getErrorMessage(error),
          variant: "error",
        })
      },
    },
  })

  const { refButton } = useGoogle({
    mutate: googleLogin,
  })

  const onSubmit = (data: IUserAuth) => {
    loginStudent({
      password: data.password,
      username: data.username,
    })
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack textAlign="left">
          <Typography
            variant="body1"
            color="primary.main"
            fontSize="24px"
            lineHeight="24px"
            fontWeight="600"
          >
            {t("noAuthFlow.student")}
          </Typography>
          <Typography variant="h2" sx={{ pt: 1, pb: 4 }}>
            {t("noAuthFlow.signIn")}
          </Typography>
          <GoogleButton text={t("noAuthFlow.signInGoogle")} ref={refButton} />
          <Typography
            textAlign="center"
            variant="subtitle1"
            color="mockup.grey80"
            py={2}
          >
            {t("defaultTranslations.or")}
          </Typography>
          <Box>
            <TextField
              name="username"
              type="text"
              label={t("noAuthFlow.userName")}
              sx={{ mb: 3 }}
            />
            <PasswordTextField />
          </Box>
          <Box sx={{ display: "flex", mt: "40px" }}>
            <Button
              sx={{ mr: "10px", maxWidth: "100px" }}
              variant="outlined"
              fullWidth
              onClick={() => dispatch(setActivePageLogin(0))}
            >
              {t("noAuthFlow.back")}
            </Button>
            <Button type="submit" fullWidth>
              {t("noAuthFlow.signIn")}
            </Button>
          </Box>
          <Typography
            textAlign="center"
            variant="body2"
            color="mockup.neutral0"
            sx={{ fontWeight: "500", mt: 2 }}
          >
            {t("noAuthFlow.dontHaveAccount")}
            <Link
              to={RoutePath.SIGNUP}
              onClick={() => dispatch(setActivePageLogin(0))}
              style={{
                color: theme.palette.mockup.primary20,
                paddingLeft: "5px",
                fontWeight: "500px",
              }}
            >
              {t("noAuthFlow.signup")}
            </Link>
          </Typography>
        </Stack>
      </form>
    </FormProvider>
  )
}

export default LoginAsStudent
