import { Typography } from "@mui/material"
import Dialog from "components/common/dialog/dialog"
import { Trans, useTranslation } from "react-i18next"

import { HREFLINK } from "../link/hreflink"

interface IProps {
  open: boolean
  onClose: () => void
  onActionButtonClick: () => void
  isLoading: boolean
  isExport?: boolean
}

const DialogUpgradeAccount = ({
  open,
  onClose,
  onActionButtonClick,
  isLoading,
  isExport,
}: IProps) => {
  const { t } = useTranslation()
  return (
    <Dialog
      open={open}
      onClose={onClose}
      isLoading={isLoading}
      titleText={
        isExport ? t("jobs.exportData") : t("coteachers.inviteCoteacher")
      }
      actionAcceptText={t("coteachers.upgrade")}
      onActionButtonClick={onActionButtonClick}
      actionAcceptButtonProps={{ disabled: isLoading }}
    >
      <Typography color="mockup.neutral10" fontWeight="500" variant="body1">
        <Trans
          i18nKey={
            isExport
              ? "jobs.descriptionUpgradeTeacherExport"
              : "coteachers.descriptionUpgradeTeacher"
          }
          components={{
            Link: HREFLINK,
          }}
        />
      </Typography>
    </Dialog>
  )
}

export default DialogUpgradeAccount
