import React, {
  Dispatch,
  FC,
  FormEvent,
  SetStateAction,
  useEffect,
} from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { CircularProgress, Divider, Stack, Typography } from "@mui/material"
import { useMutationPostTransactionsBook } from "api/reactQuery/mutations/transactionsBook"
import { useQueryStudentBalance } from "api/reactQuery/queries/studentBalance"
import { IStudentSideTransactionsAll } from "api/reactQuery/queries/studentTransactionsAll.type"
import { IResponseStudentTransactionsBooked } from "api/reactQuery/queries/studentTransactionsBooked.types"
import Dialog from "components/common/dialog/dialog"
import ErrorText from "components/common/error/errorText"
import Coin from "components/common/icon/coin"
import TransactionIcon from "components/common/icon/transactionIcon"
import CustomTextField from "components/form/common/field/textField"
import { useCustomSnackbar } from "hooks/snackbar"
import { useForm, FormProvider } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"
import {
  removeCommasNumber,
  showDecimal,
  showDecimalNegative,
} from "utils/decimalNumber"

import {
  defaultValues,
  validationSchema,
  validationSchemaMath,
} from "./TransactionDialog.config"
import { IFormContext, IFormState } from "./TransactionDialog.types"
import { getDialogTitleKey } from "./TransactionDialog.utils"

interface IProps {
  open: boolean
  onClose: () => void
  pendingData: IStudentSideTransactionsAll[] | []
  transaction?: IStudentSideTransactionsAll
  setNewData: Dispatch<SetStateAction<IStudentSideTransactionsAll[]>>
  setBookedDataTransactions?: Dispatch<
    React.SetStateAction<IResponseStudentTransactionsBooked[]>
  >
  setNextPendingTransactions: Dispatch<React.SetStateAction<number>>
}

const TransactionDialog: FC<IProps> = ({
  open,
  setNewData,
  onClose,
  transaction,
  setBookedDataTransactions,
  pendingData,
  setNextPendingTransactions,
}) => {
  const { t } = useTranslation()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()
  const { data, isLoading, isError } = useQueryStudentBalance()

  const validationSchemaCondition = (
    transaction?: IStudentSideTransactionsAll
  ) => {
    if (transaction && !transaction.is_math_enabled) {
      return validationSchemaMath
    } else {
      return validationSchema
    }
  }

  const methods = useForm<IFormState, IFormContext>({
    defaultValues,
    resolver: yupResolver(validationSchemaCondition(transaction)),
    context: {
      studentBalance: data?.data.balance,
      tranactionAmount: transaction?.amount,
    },
  })

  const { mutate: postTransaction, isLoading: isLoadingPostTransaction } =
    useMutationPostTransactionsBook({
      options: {
        onSuccess: () => {
          setNewData((prevData: IStudentSideTransactionsAll[]) =>
            prevData.filter(
              (item: IStudentSideTransactionsAll) => item.id !== transaction?.id
            )
          )

          if (!!pendingData.length) {
            setNextPendingTransactions((prev) => prev - 1)
          }

          setBookedDataTransactions && setBookedDataTransactions([])
          queryClient.refetchQueries("studentTransactionsBooked")
          queryClient.invalidateQueries(["studentBalance"])
          showSnackbar({
            title: t("dashboardStudent.transactionCalculatedCorrectly"),
          })
          onClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  useEffect(() => {
    if (!open) methods.reset()
  }, [open])

  if (!transaction) return null

  return (
    <Dialog
      open={open}
      onClose={onClose}
      titleBeforeSlot={<TransactionIcon variant={transaction.type} />}
      titleText={t(getDialogTitleKey(transaction.type))}
      actionAcceptButtonProps={{
        type: "submit",
        form: "pendingTransactionForm",
        fullWidth: false,
        disabled: isLoadingPostTransaction,
      }}
    >
      <Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          p="12px 32px"
          bgcolor="secondary.light"
          mx="-32px"
        >
          <Typography variant="subtitle1" color="mockup.grey70">
            {t("dashboardStudent.yourCurrentBalance")}:
          </Typography>
          {isError && <ErrorText />}
          {isLoading && <CircularProgress size="24px" />}
          {data?.data && !isLoading && (
            <Coin
              amount={showDecimal(`${data?.data.balance}`)}
              valueTextProps={{ fontSize: "18px", color: "mockup.grey70" }}
            />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mt="24px"
          mb="30px"
          gap="16px"
        >
          <Typography variant="subtitle1" color="mockup.grey70">
            {transaction.title}
          </Typography>
          <Coin
            amount={
              transaction.amount < 0
                ? showDecimalNegative(-transaction.amount)
                : transaction.amount
            }
            iconSize="24px"
            sign={transaction.amount < 0 ? "-" : undefined}
            signTextProps={{
              fontSize: "28px",
              lineHeight: "24px",
              color: "mockup.grey70",
            }}
            valueTextProps={{
              fontSize: "28px",
              lineHeight: "24px",
              color: "mockup.grey70",
            }}
          />
        </Stack>
        <FormProvider {...methods}>
          <Stack
            mb="24px"
            component="form"
            id="pendingTransactionForm"
            onSubmit={(e: FormEvent<HTMLFormElement>) => {
              e.preventDefault()
              methods.handleSubmit((balance: IFormState) => {
                if (!transaction?.is_math_enabled && data) {
                  postTransaction({
                    transactionId: transaction.id,
                    data: {
                      amount: data.data.balance + transaction.amount,
                    },
                  })
                  return
                }
                postTransaction({
                  transactionId: transaction.id,
                  data: {
                    amount: Number(removeCommasNumber(balance.newBalance)),
                  },
                })
              })()
            }}
          >
            {transaction.is_math_enabled ? (
              <CustomTextField
                name="newBalance"
                label={t("dashboardStudent.calculateNewAccountBalance")}
              />
            ) : (
              <>
                <Divider orientation="horizontal" />
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  gap="16px"
                >
                  <Typography variant="subtitle1" color="mockup.grey70">
                    Your new balance:
                  </Typography>
                  {data && (
                    <Coin
                      amount={showDecimal(
                        `${data?.data.balance + transaction.amount}`
                      )}
                      valueTextProps={{
                        fontSize: "18px",
                        color: "mockup.grey70",
                      }}
                    />
                  )}
                </Stack>
              </>
            )}
          </Stack>
        </FormProvider>
      </Stack>
    </Dialog>
  )
}

export default TransactionDialog
