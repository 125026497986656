import React, { useState } from "react"

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { useQueryClasses } from "api/reactQuery/queries/classes"
import { useTranslation } from "react-i18next"

import { TypeTransactions } from "../AllTransactionsTeacher"
import { typeTransactions } from "./PopoverContent.utility"

interface IPopoverContent {
  typeTransactionsArray: TypeTransactions[]
  handleCheckboxTransactions: (
    event: React.ChangeEvent<HTMLInputElement>
  ) => void
  handleCheckboxClasses: (event: React.ChangeEvent<HTMLInputElement>) => void
  onApply: (errorFrom: boolean, errorTo: boolean) => void
  onReset: () => void
  selectedDateFrom: Date | null
  selectedDateTo: Date | null
  handleDateFrom: (data: Date | null) => void
  handleDateTo: (data: Date | null) => void
  classesSelectedArray: number[]
  isLoading: boolean
}

const PopoverContent = ({
  typeTransactionsArray,
  handleCheckboxTransactions,
  onApply,
  onReset,
  handleDateFrom,
  handleDateTo,
  selectedDateFrom,
  selectedDateTo,
  classesSelectedArray,
  handleCheckboxClasses,
  isLoading,
}: IPopoverContent) => {
  const { t } = useTranslation()

  const { data } = useQueryClasses()
  const [isErrorFrom, setIsErrorFrom] = useState(false)
  const [isErrorTo, setIsErrorTo] = useState(false)

  return (
    <Stack bgcolor="common.white" p={4} maxHeight="500px">
      <Stack direction="row" columnGap={2} flexWrap="wrap" mb={2}>
        <Box flex="1" mx={2} maxWidth="210px">
          <Typography variant="body1" sx={{ mb: "14px" }}>
            {t("allTransactions.class")}
          </Typography>
          <Box sx={{ height: "215px", overflow: "auto", pl: 1 }}>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {data?.data.map((classElement) => (
                <FormControlLabel
                  key={classElement.id}
                  sx={{ mb: 2 }}
                  control={
                    <Checkbox
                      checked={classesSelectedArray.includes(classElement.id)}
                      value={classElement.id}
                      onChange={handleCheckboxClasses}
                      name={classElement.name}
                    />
                  }
                  label={
                    <Tooltip
                      title={classElement.name}
                      placement="right"
                      arrow={true}
                    >
                      <Typography
                        fontWeight="500"
                        variant="body1"
                        sx={{
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          maxWidth: "170px",
                        }}
                      >
                        {classElement.name}
                      </Typography>
                    </Tooltip>
                  }
                />
              ))}
            </FormGroup>
          </Box>
        </Box>
        <Box flex="1" mx={2}>
          <Typography variant="body1" mb="14px">
            {t("allTransactions.typeOfTransaction")}
          </Typography>
          <FormGroup>
            {typeTransactions.map((type, index) => (
              <FormControlLabel
                sx={{ mb: 2 }}
                key={index}
                control={
                  <Checkbox
                    checked={typeTransactionsArray.includes(type.toLowerCase())}
                    name={type}
                    onChange={handleCheckboxTransactions}
                  />
                }
                label={
                  <Typography fontWeight="500" variant="body1">
                    {type}
                  </Typography>
                }
              />
            ))}
          </FormGroup>
        </Box>
      </Stack>
      <Box mx={2}>
        <Typography variant="body1" mb="20px">
          {t("allTransactions.dateRange")}
        </Typography>
        <Box display="flex" columnGap={1}>
          <DatePicker
            label={t("allTransactions.dateFrom")}
            value={selectedDateFrom}
            onChange={handleDateFrom}
            renderInput={(textFieldProps) => {
              if (textFieldProps.error) {
                setIsErrorFrom(true)
              } else {
                setIsErrorFrom(false)
              }
              return (
                <TextField
                  size="small"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                    },
                  }}
                  {...textFieldProps}
                  helperText={textFieldProps.error && "Invalid Date"}
                />
              )
            }}
          />
          <DatePicker
            label={t("allTransactions.dateTo")}
            value={selectedDateTo}
            onChange={handleDateTo}
            renderInput={(textFieldProps) => {
              if (textFieldProps.error) {
                setIsErrorTo(true)
              } else {
                setIsErrorTo(false)
              }

              return (
                <TextField
                  size="small"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: "20px",
                    },
                  }}
                  {...textFieldProps}
                  helperText={textFieldProps.error ? "Invalid Date" : ""}
                />
              )
            }}
          />
        </Box>
      </Box>
      <Box display="flex" width="100%" justifyContent="flex-end" py="32px">
        <Button variant="text" onClick={onReset}>
          {t("allTransactions.resetFilters")}
        </Button>
        <Button
          disabled={isLoading}
          onClick={() => onApply(isErrorFrom, isErrorTo)}
        >
          {isLoading && (
            <span style={{ marginRight: "3px" }}>
              <CircularProgress size="12px" color="inherit" />
            </span>
          )}
          {t("allTransactions.apply")}
        </Button>
      </Box>
    </Stack>
  )
}
export default PopoverContent
