import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import qs from "qs"
import { useQuery } from "react-query"

import {
  IAccountTransactions,
  IAccountTransactionsArgs,
  IStudentAllTransactions,
  IStudentAllTransactionsArgs,
  ITeacherTransactions,
  ITeacherTransactionsArgs,
} from "./transaction.types"

export const useQueryAllTransactions = (args: IStudentAllTransactionsArgs) =>
  useQuery<AxiosResponse<IStudentAllTransactions[]>, AxiosError>(
    ["allTransactions"],
    async () =>
      axiosInstance(
        Endpoint.STUDENTS_ID_ALL_TRANSACTIONS.replace(
          "ID",
          `${args.student_id}`
        ),
        {
          params: {
            skip: args.page,
            limit: args.limit,
            pending: args.is_pending,
            type_in: args.type,
          },
          paramsSerializer: (params) => {
            return qs.stringify(params, { indices: false })
          },
        }
      ),
    {
      ...args?.options,
    }
  )

export const useQueryTeacherTransactions = (args: ITeacherTransactionsArgs) =>
  useQuery<AxiosResponse<ITeacherTransactions[]>, AxiosError>(
    ["teacherTransactions"],
    async () => {
      return axiosInstance(Endpoint.TEACHER_TRANSACTIONS, {
        params: {
          skip: args.page,
          limit: args.limit,
          classes: args.classes,
          type_in: args.type,
          date_from: args.date_from,
          date_to: args.date_to,
          title_filter: args.title_filter,
          student_filter: args.student_filter,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { indices: false })
        },
      })
    },
    { ...args?.options }
  )

export const useQueryAccountTransactions = (args?: IAccountTransactionsArgs) =>
  useQuery<AxiosResponse<IAccountTransactions[]>, AxiosError>(
    [
      "account_transactions",
      args?.transaction_type,
      args?.start_date,
      args?.end_date,
      args?.skip,
      args?.saving_account_id,
      args?.transaction_filter,
    ],
    async () => {
      return axiosInstance(Endpoint.STUDENT_ACCOUNT_TRANSACTIONS, {
        params: {
          skip: args?.skip,
          start_date: args?.start_date,
          end_date: args?.end_date,
          transaction_type: args?.transaction_type,
          saving_account_id: args?.saving_account_id,
          transaction_filter: args?.transaction_filter,
        },
      })
    },
    {
      ...args?.options,
    }
  )
