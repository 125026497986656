import { useEffect } from "react"

import LoginAsStudent from "components/module/loginAsStudent"
import LoginAsTeacher from "components/module/loginAsTeacher"
import SignAsStudent from "components/module/signAsStudent"
import SignAsTeacher from "components/module/signAsTeacher"
import { useIsBreakpointUp } from "hooks/breakpoint"
import { useAppSelector } from "hooks/store"
import {
  selectActivePageLogin,
  selectActivePageSignUp,
  selectRole,
} from "store/auth/auth.selectors"

interface IProps<T> {
  Component: React.ComponentType<T>
  registerType: "signUp" | "login"
}

function withRegisterLayout<T>({ Component, registerType }: IProps<T>) {
  function newComponent(
    props: Omit<
      T,
      "desktopView" | "activePage" | "userSelected" | "registerType"
    >
  ) {
    const desktopView = useIsBreakpointUp("desktop")
    const signUpActivePage = useAppSelector(selectActivePageSignUp)
    const loginActivePage = useAppSelector(selectActivePageLogin)
    const activeRole = useAppSelector(selectRole)

    const userSelected = () => {
      if (registerType === "signUp") {
        if (activeRole.teacher) {
          return <SignAsTeacher />
        }
        if (activeRole.student) {
          return <SignAsStudent />
        }
      }

      if (registerType === "login") {
        if (activeRole.teacher) {
          return <LoginAsTeacher />
        }
        if (activeRole.student) {
          return <LoginAsStudent />
        }
      }

      return
    }

    useEffect(() => {
      userSelected()
    }, [])

    return (
      <Component
        {...(props as T)}
        desktopView={desktopView}
        activePage={
          registerType === "signUp" ? signUpActivePage : loginActivePage
        }
        userSelected={userSelected}
        registerType={registerType}
      />
    )
  }
  return newComponent
}

export default withRegisterLayout
