import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"
import {
  IPostStudentUpdate,
  IRetrieveStudent,
  IStudentAuth,
} from "ts/interfaces/Student"

import {
  IBulkDeleteStudentsArgs,
  IPostBulkDeleteRemoveStudents,
  IPostRemoveStudent,
  IPostRemoveTransaction,
  IPostStudentFinalize,
  IPostStudentFire,
  IPostStudentHire,
  IPostStudentJobApplications,
  IPostStudentLogin,
  IPostStudentReject,
  IPostStudentSignUp,
  IPostUpdateStudentClasses,
  IPostUpdateStudentJobs,
  IRemoveStudentArgs,
  IRemoveStudentsClassArgs,
  IResponseBulkDeleteStudents,
  IResponseGetStudentToken,
  IResponseStudentJobApplications,
  IStudentFinalizeArgs,
  IStudentFireArgs,
  IStudentHireArgs,
  IStudentJobApplicationsArgs,
  IStudentLogin,
  IStudentRejectArgs,
  IStudentSignUpArgs,
  IStudentTransactionsArgs,
  IStudentUpdateArgs,
  IUpdateStudentClassesArgs,
  IUpdateStudentJobsArgs,
} from "./students.types"

export const useMutationStudentUpdate = (args?: IStudentUpdateArgs) =>
  useMutation<AxiosResponse<IRetrieveStudent>, AxiosError, IPostStudentUpdate>(
    async ({ data, params }) => {
      return await axiosInstance.patch(
        `${Endpoint.RETRIEVE_STUDENT}/${params}`,
        data
      )
    },
    args?.options
  )

export const useMutationTransactionsRemove = (arg?: IStudentTransactionsArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostRemoveTransaction>(
    async ({ studentId, transactionId }) => {
      return await axiosInstance.delete(
        `${Endpoint.STUDENT_TRANSACTION.replace(
          "ID",
          `${studentId}`
        )}/${transactionId}`
      )
    },
    arg?.options
  )

export const useMutationUpdateStudentClasses = (
  arg?: IUpdateStudentClassesArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostUpdateStudentClasses>(
    async ({ studentId, classes }) => {
      return await axiosInstance.post(
        Endpoint.STUDENT_ID_CLASSES.replace("ID", `${studentId}`),
        classes
      )
    },
    arg?.options
  )

export const useMutationHiringStudent = (arg?: IStudentHireArgs) =>
  useMutation<AxiosResponse<string>, AxiosError, IPostStudentHire>(
    async ({ classId, jobId, data }) => {
      return await axiosInstance.post(
        Endpoint.STUDENT_HIRE.replace("CLASSID", `${classId}`).replace(
          "JOBID",
          `${jobId}`
        ),
        data
      )
    },
    arg?.options
  )

export const useMutationFireStudent = (args?: IStudentFireArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStudentFire>(
    async ({ classId, jobId, data }) => {
      return await axiosInstance.post(
        Endpoint.STUDENT_FIRE.replace("CLASSID", `${classId}`).replace(
          "JOBID",
          `${jobId}`
        ),
        data
      )
    },
    args?.options
  )

export const useMutationRemoveStudent = (arg?: IRemoveStudentArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostRemoveStudent>(
    async ({ studentId }) => {
      return await axiosInstance.delete(
        `${Endpoint.RETRIEVE_STUDENT}/${studentId}`
      )
    },
    arg?.options
  )

export const useMutationBulkDeleteStudents = (args?: IBulkDeleteStudentsArgs) =>
  useMutation<
    AxiosResponse<IResponseBulkDeleteStudents>,
    AxiosError,
    IPostBulkDeleteRemoveStudents
  >(async (data) => {
    return await axiosInstance.delete(
      Endpoint.CLASSES_ID_STUDENTS.replace("ID", `${args?.class_id}`),
      data
    )
  }, args?.options)

export const useMutationRemoveStudentsClass = (
  args?: IRemoveStudentsClassArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostBulkDeleteRemoveStudents>(
    async (data) => {
      return await axiosInstance.delete(
        Endpoint.CLASSES_ID_CLASSSTUDENTS.replace("ID", `${args?.class_id}`),
        data
      )
    },
    args?.options
  )

export const useMutationStudentLogin = (arg?: IStudentLogin) =>
  useMutation<
    AxiosResponse<IResponseGetStudentToken>,
    AxiosError,
    IPostStudentLogin
  >(async (data) => {
    return await axiosInstance.post(Endpoint.STUDENTS_LOGIN_ACCESS_TOKEN, data)
  }, arg?.options)

export const useMutationStudentSignUp = (arg?: IStudentSignUpArgs) =>
  useMutation<
    AxiosResponse<IResponseGetStudentToken>,
    AxiosError,
    IPostStudentSignUp
  >(async (data) => {
    return await axiosInstance.post(Endpoint.STUDENTS_SIGN_UP, data)
  }, arg?.options)

export const useMutationEditStudentJobs = (arg?: IUpdateStudentJobsArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostUpdateStudentJobs>(
    async ({ classId, studentId, data }) => {
      return await axiosInstance.post(
        `${Endpoint.STUDENT_POSSIBLE_JOBS.replace(
          "ID",
          `${studentId}`
        )}/${classId}`,
        data
      )
    },
    arg?.options
  )

export const useMutationStudentJobApplications = (
  args?: IStudentJobApplicationsArgs
) =>
  useMutation<
    AxiosResponse<IResponseStudentJobApplications>,
    AxiosError,
    IPostStudentJobApplications
  >(async (data) => {
    return await axiosInstance.post(Endpoint.STUDENT_JOB_APPLICATIONS, data)
  }, args?.options)

export const useMutationRejectStudent = (args?: IStudentRejectArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostStudentReject>(
    async ({ classId, jobId, data }) => {
      return await axiosInstance.post(
        Endpoint.CLASSES_JOBS_REJECT.replace("CLASSID", `${classId}`).replace(
          "JOBID",
          `${jobId}`
        ),
        data
      )
    },
    args?.options
  )

export const useMutationStudentFinalize = (args?: IStudentFinalizeArgs) =>
  useMutation<AxiosResponse<IStudentAuth>, AxiosError, IPostStudentFinalize>(
    async (data) => {
      return await axiosInstance.post(Endpoint.STUDENTS_FINALIZE, data)
    },
    args?.options
  )
