import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IClearAllApplicationsArgs,
  ICreateJobsArgs,
  IDeleteJobs,
  IDeleteJobsArgs,
  IPostClearAllApplications,
  IPostCreateJobsItem,
  IPostResponseCreateJobs,
  IResponseCreateJobs,
  IUpdateJobsArgs,
} from "./jobs.types"

export const useMutationPostCreateJobs = ({ options }: ICreateJobsArgs) =>
  useMutation<
    AxiosResponse<IResponseCreateJobs[]>,
    AxiosError,
    IPostCreateJobsItem
  >(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_JOBS.replace("ID", `${classId}`),
        data
      ),
    options
  )

export const useMutationPatchCreateJobs = (args?: IUpdateJobsArgs) =>
  useMutation<
    AxiosResponse<IResponseCreateJobs[]>,
    AxiosError,
    IPostResponseCreateJobs
  >(
    async ({ classId, data }) =>
      await axiosInstance.patch(
        Endpoint.CLASSES_JOBS.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )

export const useMutationDeleteJobs = (args?: IDeleteJobsArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IDeleteJobs>(
    async ({ classId, jobId }) =>
      await axiosInstance.delete(
        `${Endpoint.CLASSES_JOBS.replace("ID", `${classId}`)}/${jobId}`
      ),
    args?.options
  )

export const useMutationClearAllApplications = (
  args?: IClearAllApplicationsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostClearAllApplications>(
    async ({ classId }) => {
      return await axiosInstance.post(
        Endpoint.CLASSES_ID_JOBS_CLEAN.replace("ID", `${classId}`)
      )
    },
    args?.options
  )
