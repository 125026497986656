import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IClassStoreItem } from "ts/interfaces/Class"

import { IGetClassesStoreItemsArgs } from "./classesStoreItems.types"

export const useQueryClassesStoreItems = ({
  classId,
  options,
}: IGetClassesStoreItemsArgs) =>
  useQuery<AxiosResponse<IClassStoreItem[]>, AxiosError>(
    ["classesStoreItems", classId],
    async () =>
      axiosInstance(Endpoint.CLASSES_STORE_ITEMS.replace("ID", `${classId}`)),
    options
  )
