export const layout = {
  dashboard: "Dashboard",
  pageNotFound: "Page not found",
  myActivity: "My activity",
  accounts: "Accounts",
  classes: "Classes",
  students: "Students",
  jobs: "Jobs",
  store: "Store",
  setup: "Setup",
  coteachers: "Co-teachers",
  yourBalance: "Your balance",
  myProfile: "My profile",
  profileSettings: "Profile settings",
  profilePicture: "Profile picture",
  accountDetails: "Account details",
  firstName: "First name",
  lastName: "Last name",
  userName: "User name",
  profileUpdatedSuccessfully: "Profile updated successfully",
  chooseYourAvatar: "Choose your avatar",
  addClass: "Add class",
  enterYourClassCode: "Enter your class code",
  classAddedSuccessfully: "Class added successfully",
  classNotFound: "Class not found",
  youAreNotPermittedToEnterThisClass:
    "You are not permitted to enter this class!",
  goToDashboard: "Go to dashboard",
  noAdmission: "No admission",
  confirmSchool: "Confirm School",
  hiUsername: "Hi {{firstname}} {{lastname}}!",
  descriptionConfirmSchool:
    "We are updating our database and just want to make sure we have you connected to the right school.\n<br/> School name: <strong>{{schoolname}}</strong> \n City: <strong>{{city}}</strong>\n Zip code: <strong>{{zipcode}}.</strong> <br/><br/> If the information above is correct, click CONFIRM.\n If the information above is not correct OR your zip code is missing, search for the correct school or create your new school and click CONFIRM",
  thisIsMySchool: "This is my school",
  thisIsNotMySchool: "This is not my school",
  thankYouForConfirmSchool: "Thank you for confirming the school",
  descriptionAfterSendEmailForWrongSchool:
    "Thanks for letting us know! We'll be sending an email to {{email}} so that we can get you matched up to the right school!<br/><br/> Not the right email? Let us know in the chat or send an email to <Link>katie@classequity.com</Link>!",
  confirm: "Confirm",
  allTransactions: "All transactions",
}
