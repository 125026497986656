import { Button, styled, ToggleButton } from "@mui/material"

export const BoxStyle = styled(ToggleButton)(({ theme }) => ({
  width: "100%",
  height: "203px",
  padding: 0,
  background: theme.palette.common.white,
  borderRadius: "16px",
  marginTop: "24px",
  "&.Mui-selected": {
    background: theme.palette.mockup.primary95,
    ":hover": {
      background: theme.palette.primary.light,
      transitionDuration: "300ms",
    },
  },
  ":hover": {
    background: theme.palette.primary.light,
    transitionDuration: "300ms",
  },
}))

export const ButtonStyled = styled(Button)({
  marginTop: "24px",
})
