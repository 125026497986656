import JobsBoardStudent from "components/module/jobsBoardStudent"
import MyActivity from "components/module/myActivity/MyActivity"
import BaseLayout from "layout/baseLayout"
import { DashboardStudentPage } from "pages"
import { ClassStoreStudentPage } from "pages/class/[classId]/store"
import { Page404 } from "pages/page404"
import { Routes as Switch, Route, Navigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

const StudentRoutes = () => {
  return (
    <Switch>
      <Route path="/" element={<BaseLayout />}>
        <Route path={RoutePath.HOMEPAGE} element={<DashboardStudentPage />} />
        <Route path={RoutePath.CLASS} element={<JobsBoardStudent />} />
        <Route
          path={RoutePath.CLASS_STORE}
          element={<ClassStoreStudentPage />}
        />
        <Route path={RoutePath.ACCOUNTS} element={<MyActivity />} />
        <Route
          path={`${RoutePath.HOMEPAGE}/login`}
          element={<Navigate replace to="/" />}
        />
        <Route
          path={`${RoutePath.HOMEPAGE}/sign-up`}
          element={<Navigate replace to="/" />}
        />
        <Route path={RoutePath.PAGE404} element={<Page404 />} />
      </Route>
    </Switch>
  )
}

export default StudentRoutes
