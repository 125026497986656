import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostClassesTransactionsArgs,
  IPostClassesTransactionsInput,
} from "./classesTransactions.types"

export const useMutationPostClassesTransactions = (
  args?: IPostClassesTransactionsArgs
) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostClassesTransactionsInput>(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_ID_TRANSACTIONS.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )
