import React, { useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import {
  Typography,
  Grid,
  Button,
  TextField,
  MenuItem,
  Box,
} from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { FormProvider, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { personalForm, selectRegistrationData } from "store/auth/auth.selectors"
import {
  setActivePageSignUp,
  setAuthData,
  setPersonalForm,
} from "store/auth/auth.slice"

import { clearAuthDataLocalStorage } from "../../../../utils/authData"
import { surveyProposition } from "../SignAsTeacher.utils"
import FormText from "./formText"
import { defaultValues, validationSchema } from "./PersonalForm.config"
import { IPersonalType } from "./PersonalForm.types"

const PersonalForm = () => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()
  const { first_name, last_name, survey } = useAppSelector(personalForm)
  const teacherRegistration = useAppSelector(selectRegistrationData)

  const [userName, setUserName] = useState({
    first_name: "",
    last_name: "",
  })
  const [surveySelected, setSurveySelected] = useState<string | undefined>("")

  const methods = useForm<IPersonalType>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })

  useEffect(() => {
    methods.setValue("first_name", first_name)
    methods.setValue("last_name", last_name)
    methods.setValue("survey", survey)
    setUserName({ first_name, last_name })
    setSurveySelected(survey)
    if (teacherRegistration !== undefined && survey?.length === 0) {
      setSurveySelected(surveyProposition[0].label)
    }
  }, [])

  const onSubmit = async (data: IPersonalType) => {
    const personalForm = {
      first_name: data.first_name,
      last_name: data.last_name,
      survey: data.survey,
    }

    const isValid = await validationSchema.isValid(data)
    if (isValid) {
      dispatch(setPersonalForm(personalForm))
      dispatch(setActivePageSignUp(3))
      mixpanel.track("sign_up", {
        event_name: "Continue",
      })
    }
  }

  const handleGetUserName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name
    const value = event.target.value
    setUserName((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Typography
          variant="h2"
          color="mockup.neutral50"
          textAlign="left"
          mb={4}
        >
          {t("noAuthFlow.getToKnowYou")}
        </Typography>
        <Grid
          container
          columnSpacing={{ tablet: "12px", mobile: 0 }}
          rowSpacing={{ mobile: "30px" }}
        >
          <FormText
            name="first_name"
            label="First name"
            handleFunction={handleGetUserName}
            value={userName.first_name}
            invalid={!!methods.formState.errors.first_name}
          />
          <FormText
            name="last_name"
            label="Last name"
            handleFunction={handleGetUserName}
            value={userName.last_name}
            invalid={!!methods.formState.errors.last_name}
          />
          <Grid item mobile={12} sx={{ mb: 5 }}>
            <TextField
              {...methods.register("survey", {
                onChange: (event) => setSurveySelected(event.target.value),
              })}
              value={surveySelected}
              select
              label={t("noAuthFlow.howDidYouHearAbout", {
                application:
                  process.env.REACT_APP_TYPE === "classequity"
                    ? "ClassEquity"
                    : "Bankee",
              })}
              name="survey"
            >
              {surveyProposition.map((survey) => (
                <MenuItem value={survey.label} key={survey.value}>
                  {survey.label}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
      </FormProvider>
      <Box display="flex" columnGap="10px" mt="10px">
        <Button
          onClick={() => {
            clearAuthDataLocalStorage()
            dispatch(setActivePageSignUp(0))
            dispatch(setAuthData(null))
          }}
          sx={{
            width: "max-content",
          }}
          variant="outlined"
        >
          {t("noAuthFlow.back")}
        </Button>
        <Button type="submit" fullWidth>
          {t("noAuthFlow.continue")}
        </Button>
      </Box>
    </form>
  )
}

export default PersonalForm
