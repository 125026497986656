import React from "react"

import { ReactComponent as Student } from "@brand_assets/svg/student_icon.svg"
import { ReactComponent as Teacher } from "@brand_assets/svg/teacher_icon.svg"
import { Grid, Typography } from "@mui/material"
import { useAppDispatch } from "hooks/store"
import { useTranslation } from "react-i18next"
import { setSelectRole } from "store/auth/auth.slice"
import { ISelectUser } from "store/auth/auth.types"

import { BoxStyle } from "../CheckRole.styles"

interface IProps {
  error: boolean
  activeRole: ISelectUser
}

const ToggleButton = ({ error, activeRole }: IProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation()

  return (
    <Grid container columnSpacing={2} justifyContent="center">
      <Grid item mobile={6}>
        <BoxStyle
          value="teacher"
          sx={(theme) => ({
            border: error
              ? `2px solid ${theme.palette.mockup.error50}`
              : `2px solid ${theme.palette.mockup.primary95}`,
          })}
          selected={activeRole.teacher}
          onChange={() =>
            dispatch(setSelectRole({ teacher: true, student: false }))
          }
        >
          <Grid container direction="column" alignItems="center">
            <Teacher />
            <Typography variant="h3" color="mockup.primary20" pt={1}>
              {t("noAuthFlow.teacher")}
            </Typography>
          </Grid>
        </BoxStyle>
      </Grid>
      <Grid item mobile={6}>
        <BoxStyle
          sx={(theme) => ({
            border: error
              ? `2px solid ${theme.palette.mockup.error50}`
              : `2px solid ${theme.palette.mockup.primary95}`,
          })}
          value="student"
          selected={activeRole.student}
          onChange={() =>
            dispatch(setSelectRole({ teacher: false, student: true }))
          }
        >
          <Grid container direction="column" alignItems="center">
            <Student />
            <Typography variant="h3" color="mockup.primary20" pt={1}>
              {t("noAuthFlow.student")}
            </Typography>
          </Grid>
        </BoxStyle>
      </Grid>
      <Grid item color={(theme) => theme.palette.mockup.error50}>
        {error && t("validations.selectRole")}
      </Grid>
    </Grid>
  )
}

export default ToggleButton
