import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosResponse, AxiosError } from "axios"
import { useQuery } from "react-query"
import { IClassStore } from "ts/interfaces/Class"

import { IGetClassesStoreArgs } from "./classesStore.types"

export const useQueryClassesStore = ({
  classId,
  options,
}: IGetClassesStoreArgs) =>
  useQuery<AxiosResponse<IClassStore>, AxiosError>(
    ["classesStore", classId],
    async () =>
      axiosInstance(Endpoint.CLASSES_STORE.replace("ID", `${classId}`)),
    options
  )
