import React from "react"

import { Typography, useTheme } from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { login } from "store/auth/auth.selectors"

import {
  setActivePageLogin,
  setActivePageSignUp,
} from "../../../store/auth/auth.slice"

interface IProps {
  isResetPassword?: boolean
  isSignInOrUp?: boolean
}

const RegisterInformation = ({ isResetPassword, isSignInOrUp }: IProps) => {
  const areYouLogin = useAppSelector(login)
  const { t } = useTranslation()
  const theme = useTheme()
  const dispatch = useAppDispatch()

  return (
    <>
      {isResetPassword && (
        <Typography variant="body2" pt={4} textAlign="left" fontWeight="500">
          {t("noAuthFlow.forgotPassword")}
          <Link
            style={{
              color: theme.palette.mockup.primary20,
              paddingLeft: "5px",
            }}
            to={RoutePath.RESET_PASSWORD}
          >
            {t("noAuthFlow.reset")}
          </Link>
        </Typography>
      )}
      {isSignInOrUp &&
        (areYouLogin ? (
          <Typography variant="body2" fontWeight="500" pt={3}>
            {t("noAuthFlow.dontHaveAccount")}
            <Link
              to={RoutePath.SIGNUP}
              onClick={() => dispatch(setActivePageLogin(0))}
              style={{
                color: theme.palette.mockup.primary20,
                paddingLeft: "5px",
              }}
            >
              {t("noAuthFlow.signup")}
            </Link>
          </Typography>
        ) : (
          <Typography variant="body2" fontWeight="500" pt={3}>
            {t("noAuthFlow.haveAccount")}
            <Link
              style={{
                color: theme.palette.mockup.primary20,
                paddingLeft: "5px",
              }}
              to={RoutePath.LOGIN}
              onClick={() => dispatch(setActivePageSignUp(0))}
            >
              {t("noAuthFlow.signIn")}
            </Link>
          </Typography>
        ))}
    </>
  )
}

export default RegisterInformation
