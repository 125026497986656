import { addNewClass } from "./modules/addNewClass"
import { allTransactions } from "./modules/allTransactions"
import { coteachers } from "./modules/coteachers"
import { dashboard } from "./modules/dashboard"
import { dashboardStudent } from "./modules/dashboardStudent"
import { defaultTranslations } from "./modules/defaultTranslations"
import { jobs } from "./modules/jobs"
import { layout } from "./modules/layout"
import { myActivity } from "./modules/myActivity"
import { noAuthFlow } from "./modules/noAuthFlow"
import { setup } from "./modules/setup"
import { store } from "./modules/store"
import { storeStudent } from "./modules/storeStudent"
import { studentJobs } from "./modules/studentJobs"
import { studentProfile } from "./modules/studentProfile"
import { students } from "./modules/students"
import { teacherSettings } from "./modules/teacherSettings"
import { transaction } from "./modules/transaction"
import { validations } from "./modules/validations"

const en = {
  teacherSettings,
  addNewClass,
  dashboard,
  defaultTranslations,
  jobs,
  layout,
  noAuthFlow,
  setup,
  store,
  studentProfile,
  students,
  transaction,
  validations,
  dashboardStudent,
  studentJobs,
  myActivity,
  storeStudent,
  coteachers,
  allTransactions,
}

export default en
