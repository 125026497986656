export function getRoleFromDomain(): "teacher" | "student" {
    const hostname = window.location.hostname
    const parts = hostname.split(".")

    let role: "teacher" | "student" = "teacher"

    if (parts[0] == "student") {
        role = "student"
    }

    return role
}