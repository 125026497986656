import RegisterLayout from "components/common/registerLayout"
import withRegisterLayout from "hoc/withRegisterLayout"
import SignSide from "layout/signSide"
import ChangePassword from "pages/password/changePassword"
import ResetPassword from "pages/password/resetPassword"
import { useLocation } from "react-router"
import { Routes as Switch, Route, Navigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"

const SignUpWrapped = withRegisterLayout({
  Component: RegisterLayout,
  registerType: "signUp",
})
const LoginWrapped = withRegisterLayout({
  Component: RegisterLayout,
  registerType: "login",
})

const UnauthRoutes = () => {
  const location = useLocation()
  return (
    <Switch>
      <Route path={RoutePath.HOMEPAGE} element={<SignSide />} />
      <Route path={RoutePath.SIGNUP} element={<SignUpWrapped />} />
      <Route path={RoutePath.LOGIN} element={<LoginWrapped />} />
      <Route path={RoutePath.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path={RoutePath.CHANGE_PASSWORD} element={<ChangePassword />} />
      <Route
        path={RoutePath.PAGE404}
        element={
          <Navigate replace to={RoutePath.LOGIN} state={{ from: location }} />
        }
      />
    </Switch>
  )
}

export default UnauthRoutes
