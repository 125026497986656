import React from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { Box, Typography } from "@mui/material"
import { useMutationGoogleLogin } from "api/reactQuery/mutations/googleLogin"
import { useMutationLoginTeacher } from "api/reactQuery/mutations/teacher"
import RegisterButtons from "components/common/button/registerButtons"
import GoogleButton from "components/common/googleButton"
import RegisterInformation from "components/common/registerInformation"
import RegisterInput from "components/common/textField/registerInput"
import { useGoogle } from "hooks/google"
import { useCustomSnackbar } from "hooks/snackbar"
import { useAppDispatch } from "hooks/store"
import { FormProvider, useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { useLocation } from "react-router"
import { useNavigate } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import {
  setActivePageLogin,
  setActivePageSignUp,
  setAuthData,
} from "store/auth/auth.slice"
import { UserRole } from "ts/enums/User"
import { LocationState } from "ts/interfaces/Route"
import { getErrorMessage } from "utils/api"

import { POLICY, TERMS } from "../signAsTeacher/SignAsTeacher.config"
import { defaultValues, validationSchema } from "./LoginAsTeacher.config"
import { IUserInfo } from "./LoginAsTeacher.types"

const LoginAsTeacher = () => {
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const from = (location.state as LocationState)?.from?.pathname

  const methods = useForm<IUserInfo>({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
  })

  const { mutate: loginTeacher, isLoading: isLoadingLogin } =
    useMutationLoginTeacher({
      options: {
        onSuccess: (data, variables) => {
          if (data.data.finalization_required) {
            dispatch(
              setAuthData({
                token: data.data.access_token,
                finalized: false,
                role: UserRole.TEACHER,
              })
            )
            navigate(RoutePath.SIGNUP)
            dispatch(setActivePageSignUp(2))
            return
          }
          showSnackbar({
            title: t("noAuthFlow.loginSuccessful"),
          })
          dispatch(
            setAuthData({
              token: data.data.access_token,
              finalized: true,
              role: UserRole.TEACHER,
            })
          )

          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "login",
            user: variables.username,
          })
          if (JSON.parse(localStorage.getItem("isBadAccount") ?? "false")) {
            navigate("/settings", { replace: true })
            localStorage.removeItem("isBadAccount")
            return
          }
          navigate(from ?? RoutePath.HOMEPAGE, {
            replace: true,
            state: { from: location },
          })
        },
        onError: (error) => {
          showSnackbar({
            title: getErrorMessage(error),
            variant: "error",
          })
        },
      },
    })

  const { mutate } = useMutationGoogleLogin({
    option: {
      onSuccess: (data) => {
        dispatch(
          setAuthData({
            token: data.data.access_token,
            role: UserRole.TEACHER,
            finalized: !data.data.finalization_required,
          })
        )
        showSnackbar({
          title: t(
            data.data.finalization_required
              ? "noAuthFlow.emailAndPasswordCreated"
              : "noAuthFlow.loginSuccessful"
          ),
        })
        if (data.data.finalization_required) {
          navigate(RoutePath.SIGNUP)
          dispatch(setActivePageSignUp(2))
        } else {
          navigate(from ?? RoutePath.HOMEPAGE, {
            replace: true,
            state: { from: location },
          })
        }
      },
      onError: (error) => {
        showSnackbar({
          title: t(getErrorMessage(error)),
          variant: "error",
        })
      },
    },
  })

  const { refButton } = useGoogle({ mutate })

  const onSubmit = (data: IUserInfo) => {
    loginTeacher({
      password: data.password,
      username: data.username,
    })
  }

  return (
    <form onSubmit={methods.handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <Box textAlign="left">
          <Typography
            variant="body1"
            color="primary.main"
            fontSize="24px"
            lineHeight="24px"
            fontWeight="600"
          >
            {t("noAuthFlow.teacher")}
          </Typography>
          <Typography variant="h2" color="mockup.neutral50" pt={1} pb={4}>
            {t("noAuthFlow.signIn")}
          </Typography>
        </Box>
        <GoogleButton text={t("noAuthFlow.signInGoogle")} ref={refButton} />
        <Typography variant="subtitle1" color="mockup.grey80" py={2}>
          {t("defaultTranslations.or")}
        </Typography>
        <Box>
          <RegisterInput name="username" type="email" />
          <RegisterInformation isResetPassword />
          {process.env.REACT_APP_TYPE === "bankeeonline" && (
            <Box mt={2}>
              <Typography
                variant="body2"
                sx={(theme) => ({
                  fontWeight: "500",
                  color: theme.palette.mockup.neutral0,
                  textAlign: "left",
                })}
              >
                <Trans
                  i18nKey="noAuthFlow.bySigningUpYouAgreeToClassBanksTermsOfServiceAndPrivacyPolicy"
                  components={{
                    Link1: TERMS,
                    Link2: POLICY,
                  }}
                />
              </Typography>
            </Box>
          )}
          <RegisterButtons
            isLoading={isLoadingLogin}
            submitButton={t("noAuthFlow.signIn")}
            onClick={() => dispatch(setActivePageLogin(0))}
          />
          <RegisterInformation isSignInOrUp />
        </Box>
      </FormProvider>
    </form>
  )
}

export default LoginAsTeacher
