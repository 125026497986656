import { FC } from "react"

import { Typography } from "@mui/material"
import { useMutationDeleteClassesStoreItems } from "api/reactQuery/mutations/classesStoreItems"
import Dialog from "components/common/dialog/dialog"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { IClassStoreItem } from "ts/interfaces/Class"
import { getErrorMessage } from "utils/api"

interface IProps {
  isOpen: boolean
  handleClose: () => void
  item: IClassStoreItem
  dashboardIcons?: boolean
}

const DialogDelete: FC<IProps> = ({
  isOpen,
  dashboardIcons,
  handleClose,
  item,
}) => {
  const { t } = useTranslation()
  const classId = dashboardIcons ? item.class_?.id : useClassId()
  const { showSnackbar } = useCustomSnackbar()
  const queryClient = useQueryClient()

  const { mutate: deleteItem, isLoading: isLoadingDelete } =
    useMutationDeleteClassesStoreItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["classesStoreItems", Number(classId)])
          queryClient.invalidateQueries(["dashboardStoreUpdates"])
          showSnackbar({
            title: t("store.itemHasBeenDeleted", {
              name: item.name,
            }),
          })
          handleClose()
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  return (
    <Dialog
      titleText={t("store.areYouSureYouWantToDelete")}
      open={isOpen}
      onClose={handleClose}
      actionAcceptText={t("store.delete")}
      isLoading={isLoadingDelete}
      onActionButtonClick={() => {
        deleteItem({
          classId: Number(classId),
          data: {
            item_ids: [item.id],
          },
        })
      }}
    >
      <Typography variant="subtitle2" fontWeight={500} color="mockup.neutral10">
        {t("store.thisCanNotBeRestored")}
      </Typography>
    </Dialog>
  )
}

export default DialogDelete
