import { FC, memo } from "react"

import { useMutationPostClassesStoreItems } from "api/reactQuery/mutations/classesStoreItems"
import { useClassId } from "hooks/navigation"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { useQueryClient } from "react-query"
import { getErrorMessageFromTab } from "utils/api"

import ItemDialogForm from "../../itemDialogForm"

interface IProps {
  isOpen: boolean
  onClose: () => void
}

const DialogAddItem: FC<IProps> = ({ isOpen, onClose }) => {
  const { t } = useTranslation()
  const classId = useClassId()
  const queryClient = useQueryClient()
  const { showSnackbar } = useCustomSnackbar()

  const { mutateAsync: postItemAsync, isLoading } =
    useMutationPostClassesStoreItems({
      options: {
        onSuccess: () => {
          queryClient.invalidateQueries(["classesStoreItems", Number(classId)])

          showSnackbar({
            title: t("store.itemHasBeenAddedSuccessfully"),
          })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessageFromTab(error)),
            variant: "error",
          })
        },
      },
    })

  return (
    <ItemDialogForm
      open={isOpen}
      onClose={onClose}
      title={t("store.addItem")}
      isLoading={isLoading}
      onSubmit={(data) =>
        postItemAsync({
          classId: Number(classId),
          data: {
            items: [
              {
                icon_url: data.imageUrl,
                price: Number(data.amount),
                name: data.title,
                quantity_in_stock: !data.quantity
                  ? null
                  : Number(data.quantity),
              },
            ],
          },
        })
      }
    />
  )
}

export default memo(DialogAddItem)
