import { FC } from "react"

import { ReactComponent as IconCheck } from "@common_assets/svg/check.svg"
import { ReactComponent as IconClose } from "@common_assets/svg/cross.svg"
import {
  useMutationPostStoreTransactionAccept,
  useMutationPostStoreTransactionReject,
} from "api/reactQuery/mutations/storeTransactions"
import FabIconButton from "components/common/button/fabIconButton"
import FabGroup from "components/common/container/fabGroup"
import { useCustomSnackbar } from "hooks/snackbar"
import { useTranslation } from "react-i18next"
import { ITransactionItemClass } from "ts/interfaces/Transaction"
import { getErrorMessage } from "utils/api"

interface IProps {
  id: number
  onSuccessCallback: () => void
  classId?: string
  setSelectedItems?: React.Dispatch<
    React.SetStateAction<ITransactionItemClass[]>
  >
}

const TransactionActionButtons: FC<IProps> = ({
  id,
  onSuccessCallback,
  classId,
  setSelectedItems,
}) => {
  const { showSnackbar } = useCustomSnackbar()
  const { t } = useTranslation()

  const { isLoading: isLoadingAccept, mutate: mutateAccept } =
    useMutationPostStoreTransactionAccept({
      options: {
        onSuccess: (_, variables) => {
          setSelectedItems &&
            setSelectedItems((prev) =>
              prev.filter((element) => element.id !== variables.id)
            )
          if (!!window.Intercom) {
            window.Intercom("trackEvent", "accepted transaction", {
              classId: classId,
              numberAcceptedTransaction: 1,
            })
          }
          window.dataLayer = window.dataLayer || []
          window.dataLayer.push({
            event: "acceptedTransaction",
            classId: classId,
            numberAcceptedTransaction: 1,
          })
          onSuccessCallback()
          showSnackbar({ title: t("transactionAccepted") })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  const { isLoading: isLoadingReject, mutate: mutateReject } =
    useMutationPostStoreTransactionReject({
      options: {
        onSuccess: (_, variables) => {
          setSelectedItems &&
            setSelectedItems((prev) =>
              prev.filter((element) => element.id !== variables.id)
            )
          onSuccessCallback()
          showSnackbar({ title: t("transactionRejected") })
        },
        onError: (error) => {
          showSnackbar({
            title: t(getErrorMessage(error)),
            variant: "error",
          })
        },
      },
    })

  return (
    <FabGroup>
      <FabIconButton
        onClick={() => mutateReject({ id })}
        Icon={<IconClose />}
        isLoading={isLoadingReject}
        tooltipText={t("rejectTransaction")}
      />
      <FabIconButton
        onClick={() => mutateAccept({ id })}
        Icon={<IconCheck />}
        isLoading={isLoadingAccept}
        tooltipText={t("acceptTransaction")}
      />
    </FabGroup>
  )
}

export default TransactionActionButtons
