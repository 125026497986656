import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useMutation } from "react-query"

import {
  IPostClassesStoreArgs,
  IPostClassesStoreInput,
} from "./classesStore.types"

export const useMutationPostClassesStore = (args?: IPostClassesStoreArgs) =>
  useMutation<AxiosResponse<null>, AxiosError, IPostClassesStoreInput>(
    async ({ classId, data }) =>
      await axiosInstance.post(
        Endpoint.CLASSES_STORE.replace("ID", `${classId}`),
        data
      ),
    args?.options
  )
