import { RefObject, useEffect, useRef, useState } from "react"

import { useMediaQuery } from "@mui/material"
import { GOOGLE_CLIENT_ID } from "api/api.config"
import { setGoogleToken } from "store/auth/auth.slice"
import { size } from "utils/constants/size"

import { useAppDispatch } from "../store"

interface IProps {
  mutate: ({ token }: { token: string }) => void
  isDispatch?: boolean
}

export const useGoogle = ({ mutate, isDispatch = false }: IProps) => {
  //@ts-ignore
  const google = window.google
  const [token, setToken] = useState<string | null>(null)
  const dispatch = useAppDispatch()

  const refButton: RefObject<HTMLDivElement> = useRef(null)

  const clientId = GOOGLE_CLIENT_ID
  const isTablet = useMediaQuery(size.MIN_WIDTH_410)
  const isMobile = useMediaQuery(size.MAX_WIDTH_375)

  const handleGoogleRegister = async (res: { credential: string }) => {
    mutate({ token: res.credential })
    setToken(res.credential)
    if (isDispatch) dispatch(setGoogleToken(res.credential))
  }
  useEffect(() => {
    if (!google) return
    google.accounts.id.initialize({
      client_id: clientId,
      callback: handleGoogleRegister,
    })

    google.accounts.id.renderButton(refButton.current, {
      width: isTablet ? "400px" : !isMobile ? "360px" : "330px",
    })
  }, [isTablet, isMobile])

  return {
    token,
    refButton,
  }
}
