import axiosInstance from "api"
import { Endpoint } from "api/enpoints.types"
import { AxiosError, AxiosResponse } from "axios"
import { useQuery } from "react-query"

import {
  IJobApplicationsArgs,
  IResponseJobApplications,
} from "./jobApplications.types"

export const useQueryJobApplications = (args?: IJobApplicationsArgs) => {
  useQuery<AxiosResponse<IResponseJobApplications>, AxiosError>(
    ["getJobApplication", args?.job_application_id],
    async () =>
      axiosInstance(`${Endpoint.JOB_APPLICATIONS}/${args?.job_application_id}`),
    {
      ...args?.options,
    }
  )
}
