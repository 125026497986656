import { FC } from "react"

import { Button, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { RoutePath } from "routes/Route.types"

const SignSide: FC = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  return (
    <Stack
      spacing={3}
      direction="column"
      justifyContent="center"
      height="400px"
      alignItems="center"
    >
      <Button
        onClick={() => {
          navigate(RoutePath.LOGIN)
        }}
        sx={{ px: 6, py: 3 }}
      >
        {t("noAuthFlow.login")}
      </Button>
      <Button
        onClick={() => {
          navigate(RoutePath.SIGNUP)
        }}
        sx={{ px: 6, py: 3 }}
      >
        {t("noAuthFlow.signup")}
      </Button>
    </Stack>
  )
}

export default SignSide
