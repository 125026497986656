import React, { FC, useEffect, useState } from "react"

import { Box, Typography } from "@mui/material"
import { useAppDispatch, useAppSelector } from "hooks/store"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { selectRole, login } from "store/auth/auth.selectors"
import {
  setActivePageSignUp,
  setActivePageLogin,
  setSelectRole,
} from "store/auth/auth.slice"
import { getRoleFromDomain } from "utils/domain"

import { ISelectUser } from "../../../store/auth/auth.types"
import RegisterInformation from "../registerInformation"
import { ButtonStyled } from "./CheckRole.styles"
import ToggleButton from "./ToggleButton"

const checkRole: FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const activeRole = useAppSelector(selectRole)
  const areYouLogin = useAppSelector(login)

  const [error, setError] = useState<boolean>(false)

  const onSelectedUser = () => {
    if (!activeRole.teacher && !activeRole.student) {
      setError(true)
      return
    }

    if (process.env.REACT_APP_DOMAIN_SPLIT == "1") {
      const activeRoleName = activeRole.teacher ? "teacher" : "student"
      if (activeRoleName != getRoleFromDomain()) {
        let redirectUrl: string | undefined = ""
        if (activeRoleName == "student") {
          redirectUrl = process.env.REACT_APP_STUDENT_URL
        } else {
          redirectUrl = process.env.REACT_APP_TEACHER_URL
        }
        window.location.href = redirectUrl + window.location.pathname
        return
      }
    }

    const selectedUser = Object.keys(activeRole).find(
      (key): key is keyof ISelectUser => activeRole[key as keyof ISelectUser]
    )

    mixpanel.track("sing_up_role", {
      role: selectedUser,
      auth: pathname,
    })
    dispatch(areYouLogin ? setActivePageLogin(1) : setActivePageSignUp(1))
  }

  useEffect(() => {
    if (activeRole.teacher || activeRole.student) {
      setError(false)
    }
  }, [activeRole])

  useEffect(() => {
    if (process.env.REACT_APP_DOMAIN_SPLIT == "1") {
      const newActiveRole = { teacher: false, student: false }
      newActiveRole[getRoleFromDomain()] = true
      dispatch(setSelectRole(newActiveRole))
    }
  }, [])

  const handleBackPage = () => {
    dispatch(setSelectRole({ teacher: false, student: false }))
    window.location.href = "https://www.classbank.com/"
  }

  return (
    <>
      <Typography textAlign="left" variant="h2" color="mockup.neutral10">
        {areYouLogin ? t("noAuthFlow.signInAs") : t("noAuthFlow.signUpAs")}
      </Typography>
      <ToggleButton error={error} activeRole={activeRole} />
      <Box display="flex" position="relative">
        {process.env.REACT_APP_TYPE === "classequity" && (
          <ButtonStyled
            sx={{ maxWidth: "100px", mr: "10px" }}
            fullWidth
            variant="outlined"
            onClick={handleBackPage}
          >
            {t("noAuthFlow.back")}
          </ButtonStyled>
        )}

        <ButtonStyled fullWidth onClick={onSelectedUser}>
          {t("noAuthFlow.continue")}
        </ButtonStyled>
      </Box>
      <RegisterInformation isSignInOrUp />
    </>
  )
}

export default checkRole
