import React from "react"

import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useTheme,
} from "@mui/material"
import { useAppSelector } from "hooks/store"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { RoutePath } from "routes/Route.types"
import { selectDataAuth } from "store/auth/auth.selectors"
import { IAuthData } from "ts/interfaces/Auth"

import { ErrorStyle } from "../../../common.style"

interface IProps {
  email: string
  error: boolean
  isLoadingRecoverPassword: boolean
  handleClick: () => void
  setEmail: React.Dispatch<React.SetStateAction<string>>
}

const First = ({
  email,
  error,
  isLoadingRecoverPassword,
  handleClick,
  setEmail,
}: IProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const isLogin = useAppSelector(selectDataAuth) as IAuthData

  return (
    <>
      <Typography
        variant="h2"
        color="mockup.neutral50"
        sx={{ textAlign: "left" }}
      >
        {t("noAuthFlow.enterEmailToResetYourPassword")}
      </Typography>
      <Box sx={{ position: "relative" }}>
        <TextField
          error={Boolean(error)}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          name="reset_password"
          label={t("noAuthFlow.email")}
          sx={{ mt: 4, mb: 3 }}
        />
        {error && <ErrorStyle>The field is required</ErrorStyle>}
      </Box>
      <Button
        disabled={isLoadingRecoverPassword}
        fullWidth
        onClick={handleClick}
        sx={{ mt: 2 }}
      >
        {isLoadingRecoverPassword && (
          <CircularProgress
            size="18px"
            sx={{
              color: "common.white",
            }}
          />
        )}
        {t("noAuthFlow.restorePassword")}
      </Button>
      {isLogin === null ? (
        <>
          <Typography variant="body2" sx={{ mt: 2, fontWeight: 500 }}>
            {t("noAuthFlow.dontHaveAccount")}
            <Link
              style={{
                color: theme.palette.mockup.primary20,
                paddingLeft: "5px",
              }}
              to={RoutePath.SIGNUP}
            >
              {t("noAuthFlow.signup")}
            </Link>
          </Typography>
        </>
      ) : null}
    </>
  )
}

export default First
